$durationHalf: 750ms;
$header-height: 1100px;

body {
    margin: 0;
}

@font-face {
    font-family: "Tw Cen MT";
    /* src: local('Tw Cen MT'), url("./TwCenMT.ttf") format("truetype"); */
    src: url("./font/TwCenMT.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Proxon";
    /* src: local('Tw Cen MT'), url("./TwCenMT.ttf") format("truetype"); */
    src: url("./font/PROXON.ttf") format("truetype");
    src: url("./font/PROXON.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

#forReact {
    background-color: black;
    /* background-color: white; */
}

.hoverPolygon {
    transition-property: stroke-opacity, fill-opacity;
    transition-duration: 1000ms;
    transition-timing-function: cubic-bezier(0.4, 0.6, 0.4, 0.6);
    stroke: white;
    /* stroke-opacity: 1;
    opacity: 1; */
    stroke-width: 5px;
    /* fill: black; */
    z-index: 10;
}

/* .underPolyline {
    fill: transparent;
    stroke: red;
    stroke-width: 5px;

    animation-name: go_down;
	animation-duration: 2000ms;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

.underPolyline.delay {
	animation-delay: 1000ms;
}

@keyframes go_down
{
	0% { 
        transform: translateY(-40px);
        stroke-opacity: 0;
    }

    50% { 
        transform: translateY(0px);
        stroke-opacity: 1;
    }
	
    100% { 
        transform: translateY(40px);
        stroke-opacity: 0;
    }
} */

.underPolyline {
    fill: transparent;
    stroke: white;
    stroke-width: 5px;

    animation-name: go_down;
    animation-duration: 2 * $durationHalf;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;

    &.delay {
        animation-delay: $durationHalf;
    }
}

@keyframes go_down {
    0% {
        transform: translateY(0px);
        stroke-opacity: 1;
    }

    100% {
        transform: translateY(40px);
        stroke-opacity: 0;
    }
}

.side {
    stroke: #65A2BA; // coral; // 
    stroke: white; // coral; // 
    transition-property: stroke-opacity;
    transition-duration: 1000ms;
    transition-timing-function: cubic-bezier(0.4, 0.6, 0.4, 0.6);
    stroke-opacity: 1;

    // filter: url(#shadow);
    /* stroke: black; */
}

.pointer {
    stroke: white;
}

.text {
    stroke: white;
    fill: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* font-family: 'Tw Cen MT';
    font-family: 'Proxon'; */
}

#main {
}

.bigHeader {
    display: flex;
    height: $header-height;

    #titleCont {
        width: 600px;
        padding-top: 200px;
        padding-left: 50px;
        background-color: black;
        color: white;
        /* float: left; */
    }
    
    #title {
        font-size: 96px;
        font-family: /* "Proxon", */ "Tw Cen MT", Verdana;
    }
    
    #subtitle {
        width: 600px;
        margin-top: 24px;
        font-size: 36px;
        font-family: /* "Proxon", */ "Tw Cen MT", Verdana;
    }
    
    #svgContainer {
        width: 100%;
        background-color: black;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

#content {
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    .sectorContainer {
        .sectorTitle {
            font-size: 120%;
            margin-bottom: 20px;
        }

        .sectorBody {
            .problem, 
            .solution {
                font-weight: bold;
            }
    
        }
    
        margin-bottom: 40px;
    }
}